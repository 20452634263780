$offset: 0.3rem;

.outlinedButton {
  transition: transform 150ms;
  z-index: 1;
  display: block;
  position: relative;
  transform: translate(0, 0);
}

@media (hover: hover) {
  .outlinedButton {
    &:hover {
      transform: translate($offset, -$offset);
    }

    &:active {
      transform: translate(($offset/1.5), -($offset/1.5));
    }
  }
}

.outlinedButtonContainer {
  position: relative;

  &:before {
    z-index: 0;
    content: '';
    @apply block rounded-lg border-2 absolute inset-0 pointer-events-none select-none;
  }
}
