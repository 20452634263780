$offset: 0.3rem;

.filledButton {
  transition: box-shadow 150ms, transform 150ms;
  box-shadow: 0 0 0 0 var(--tw-shadow-color);
  transform: translate(0, 0);
}

@media (hover: hover) {
  .filledButton:not([disabled]) {
    &:hover {
      box-shadow: -$offset $offset 0 0 var(--tw-shadow-color);
      transform: translate($offset, -$offset);
    }

    &:active {
      box-shadow: -($offset/1.5) ($offset/1.5) 0 0 var(--tw-shadow-color);
      transform: translate(($offset/1.5), -($offset/1.5));
    }
  }

  .filledButton:disabled {
    opacity: 0.9
  }
}
